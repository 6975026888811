export const SUPER_ADMINS = new Set([
  'emmanuel@trystagezero.com',
  'manu@trystagezero.com'
])

export const useAuth = () =>
  useState<Auth>('auth', () => ({
    token: { type: 'bearer', token: '' },
    user: undefined
  }))

export const useRouteAfterAuth = () =>
  useState('routeAfterAuth', () => '/dashboard')

export const useLogout = () => {
  const { axios } = useApi()
  const auth = useAuth()
  const { mutate } = useMutation({
    mutationFn: () => {
      localStorage.removeItem('token')
      navigateTo('/auth/login')
      auth.value = { token: { type: 'bearer', token: '' }, user: undefined }
      return axios.post('/auth/logout')
    }
  })
  return mutate
}

export const useFetchAuth = () => {
  const {
    axios,
    onError,
    toast: { addToast }
  } = useApi()
  const auth = useAuth()
  const logout = useLogout()
  const { $sentrySetUser } = useNuxtApp()

  const query = useQuery({
    queryFn: async () => {
      const token = localStorage.getItem('token')
      if (!token) {
        return navigateTo('/auth/login', { replace: true })
      }
      if (auth.value.user) {
        $sentrySetUser?.(auth.value.user)
        return auth
      }

      const response = await axios.get<ApiShow<Partial<Auth>>>('auth/me', {
        params: { relations: userRelations }
      })
      const { user, domainWorkspace } = response.data.data
      auth.value = {
        token: { type: 'bearer', token },
        user,
        domainWorkspace
      }

      if (user) $sentrySetUser?.(user)

      const noWorkspaceRoute = getNoWorkspaceRoute(user, domainWorkspace)
      if (noWorkspaceRoute) {
        navigateTo(noWorkspaceRoute, { replace: true })
      }
      return auth
    },
    queryKey: ['auth']
  })

  watch(query.isError, (value) => {
    if (value === true) logout()
  })

  const roles = computed<Set<RoleType>>(
    () => new Set(auth.value.user?.roles?.map((role) => role.type) ?? [])
  )

  const update = useMutation({
    mutationFn: async (request: UserRequest) => {
      const { data } = await axios.put<ApiShow<User>>(
        `users/${auth.value.user?.id}`,
        {
          ...request,
          relations: userRelations
        }
      )
      auth.value.user = data.data
      addToast({ type: 'info', title: 'Change saved' })
    },
    onError
  })

  return { auth, query, roles, update, logout }
}

export const getNoWorkspaceRoute = (
  user?: User,
  domainWorkspace?: Workspace
) => {
  if (user?.workspace) {
    return
  }
  return domainWorkspace
    ? '/register/join-workspace'
    : '/register/new-workspace'
}

export const useIsStageZero = () => {
  const auth = useAuth()

  const isStageZero = computed(
    () => !!auth.value.user && SUPER_ADMINS.has(auth.value.user.email)
  )
  return isStageZero
}

export const userRelations = [
  'roles',
  'settings',
  'workspace',
  'workspaces',
  'workspaceRequests'
] as const

export const useUpdateUser = (
  user: User,
  body: UserFormT,
  onSuccess: () => void
) => {
  const { axios, refetchQuery, onError } = useApi()

  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.put(`users/${user.id}`, body)
      await Promise.all([refetchQuery(['workspaces']), refetchQuery(['users'])])
      return data
    },
    onError,
    onSuccess
  })
}
